<template>
    <div id="Universes" :class="{isFilterOpen: checkFilterOpen}">
        <loader-component v-if="showLoader"></loader-component>
        <mds-layout-grid>
            <mds-row style="margin: 0; padding: 0">
                <div
                    class="leftBlock"
                    :class="{ showFilterLeftBlock: showFilterPanel }"
                    :style="cssVariables"
                >
                    <div class="universesHeaderSection">
                        <div>
                            <h2 class="universes-header">Universes</h2>
                        </div>
                        <div
                            class="headerActionButton"
                            style="margin-top: 25px"
                        >
                            <header-button-panel
                                :buttonDetails="buttonPanelDetails"
                                :counterDetails="counterDetails"
                                @panelButtonClicked="onClickPanelButton"
                            ></header-button-panel>
                        </div>
                    </div>
                    <div class="universesTable">
                        <div class="universe_middleSection">
                            <div class="universe_table_section">
                                <mds-table
                                    multiselection
                                    row-hover
                                    show-sortable
                                    v-if="universeLength > 0"
                                >
                                    <mds-thead
                                        @mds-thead-multiselection-changed="
                                            handleMultiSelection($event)
                                        "
                                        :indeterminate="
                                            selectionStatus.isIndeterminate
                                        "
                                        :checked="selectionStatus.isAllSelected"
                                    >
                                        <mds-th
                                            v-for="(
                                                header, index
                                            ) in universesHeader"
                                            :key="index"
                                            :sortable="header.sortable"
                                            :sorted="header.sorted"
                                            :width="header.width"
                                            :right-aligned="
                                                header.align === 'right'
                                            "
                                            @mds-th-sort-changed="
                                                handleSortEvent(
                                                    index,
                                                    header.fieldName,
                                                    $event
                                                )
                                            "
                                        >
                                            {{ header.text }}
                                        </mds-th>
                                    </mds-thead>
                                    <mds-tbody>
                                        <mds-tr
                                            v-for="(row, index) in universesRowData"
                                            :key="index"
                                            :checked="row.checked"
                                            :multiselection-label="row.name"
                                            @mds-tr-multiselection-changed="
                                                handleRowSelectEvent(
                                                    index,
                                                    $event,
                                                    row
                                                )
                                            "
                                        >
                                            <mds-td
                                                v-for="(
                                                    header, i
                                                ) in universesHeader"
                                                :key="i"
                                                :right-aligned="
                                                    header.align === 'right'
                                                "
                                            >
                                                <template
                                                    v-if="
                                                        header.fieldName ===
                                                        'actions'
                                                    "
                                                >
                                                    <span
                                                        ><mds-button
                                                            v-if="
                                                                row.iseditable ==
                                                                true
                                                            "
                                                            :disabled="
                                                                editActionButton
                                                                    || !(permissions.includes('universes-edit')
                                                                    || permissions.includes('*'))
                                                            "
                                                            v-on:click="
                                                                singalEditActionButton(
                                                                    row
                                                                )
                                                            "
                                                            class="actionButton"
                                                            icon="pencil"
                                                            variation="icon-only"
                                                            type="button"
                                                        ></mds-button
                                                    ></span>
                                                    <span
                                                        ><mds-button
                                                            v-if="
                                                                row.iseditable ==
                                                                false
                                                            "
                                                            :disabled="
                                                                viewActionButton
                                                                    || !(permissions.includes('universes-view')
                                                                    || permissions.includes('*'))
                                                            "
                                                            v-on:click="
                                                                singalViewActionButton(
                                                                    row
                                                                )
                                                            "
                                                            class="actionButton"
                                                            icon="eye"
                                                            variation="icon-only"
                                                            type="button"
                                                        ></mds-button
                                                    ></span>
                                                    <span>
                                                        <mds-button
                                                            v-if="row.isduplicate == true"
                                                            :disabled="
                                                                duplicateActionButton
                                                                    || !(permissions.includes('universes-duplicate')
                                                                    || permissions.includes('*'))
                                                            "
                                                            v-on:click="
                                                                singalDuplicateActionButton(
                                                                    row
                                                                )
                                                            "
                                                            @click="
                                                                toggleDuplicate =
                                                                    !toggleDuplicate
                                                            "
                                                            class="actionButton"
                                                            icon="open-new"
                                                            variation="icon-only"
                                                            type="button"
                                                        ></mds-button>
                                                        <mds-button
                                                            v-if="row.isduplicate == false"
                                                            disabled
                                                            class="actionButton"
                                                            icon="open-new"
                                                            variation="icon-only"
                                                            type="button"
                                                        ></mds-button>
                                                    </span>
                                                    <span
                                                        ><mds-button
                                                            :id="row.listid"
                                                            :disabled="
                                                                !row.isshare ||
                                                                (shareActionButton
                                                                    || !(permissions.includes('universes-share')
                                                                    || permissions.includes('*')))
                                                            "
                                                            @click="
                                                                singalShareActionButton(
                                                                    row
                                                                )
                                                            "
                                                            class="actionButton"
                                                            icon="share"
                                                            variation="icon-only"
                                                            type="button"
                                                        ></mds-button
                                                    ></span>
                                                    <span
                                                        v-if="
                                                            row.listid === toggleID
                                                                ? true
                                                                : false
                                                        "
                                                    >
                                                        <popover-component
                                                            v-if="
                                                                togglerowSharePopover
                                                            "
                                                            :popOverSelection="
                                                                popOverSelection
                                                            "
                                                            width="200px"
                                                            position="bottom-center"
                                                            :triggerBy="
                                                                triggerByPopover(
                                                                    row.listid
                                                                )
                                                            "
                                                            v-model="
                                                                togglerowSharePopover
                                                            "
                                                            @clickOnShareData="
                                                                clickOnShareData
                                                            "
                                                            @clickOnPublishData="
                                                                clickOnPublishData
                                                            "
                                                            @close="
                                                                togglerowSharePopover = false
                                                            "
                                                        ></popover-component>
                                                    </span>
                                                    <span
                                                        ><mds-button
                                                            :disabled="
                                                                !row.isdelete ||
                                                                (deleteActionButton
                                                                    || !(permissions.includes('universes-delete')
                                                                    || permissions.includes('*')))
                                                            "
                                                            v-on:click="
                                                                singleDeleteActionButton(
                                                                    row
                                                                )
                                                            "
                                                            @click="
                                                                toggleDelete =
                                                                    !toggleDelete
                                                            "
                                                            class="actionButton"
                                                            icon="trash"
                                                            variation="icon-only"
                                                            type="button"
                                                        ></mds-button
                                                    ></span>
                                                </template>
                                                <template
                                                    v-if="
                                                        header.fieldName ===
                                                        'lastupdated'
                                                    "
                                                >
                                                    {{
                                                        convertDate(
                                                            row[header.fieldName]
                                                        )
                                                    }}
                                                </template>
                                                <template v-else>
                                                    {{ row[header.fieldName] }}
                                                </template>
                                            </mds-td>
                                        </mds-tr>
                                    </mds-tbody>
                                </mds-table>
                                
                                <mds-empty-state
                                    v-if="universesRowData == undefined"
                                    class="no-results-message"
                                    size="medium"
                                    title="No results matched"
                                    message="We weren't able to match any results with your current selections. Please adjust your filter criteria and try again."
                                ></mds-empty-state>
                            </div>
                        </div>
                        <mds-pagination
                            :class="[
                                showFilterPanel === true
                                    ? 'move-pagination-show-info'
                                    : '',
                                'pagination-component',
                            ]"
                            v-if="universeLength > 0"
                            :key="paginationComponentKey"
                            show-items-info
                            show-items-select
                            :total-items="totalItems"
                            :pageSize="pageSize"
                            :pageSizes="[10, 20, 50]"
                            @mds-pagination-page-changed="paginateTable"
                            style="margin-top: 10px; margin-right: 3px"
                        ></mds-pagination>
                    </div>
                </div>

                <div
                    class="rightBlock"
                    :class="{ showFilterRightBlock: showFilterPanel }"
                    :style="cssVariables"
                >
                    <div class="filterPanel">
                        <transition-group name="fade" class="group-4">
                            <filter-panel
                                ref="fiterpanel"
                                :key="checkBoxFilterKey"
                                :filterData="filterObject"
                                @filterEvent="searchFilter"
                                @multiSelectFilterChange="
                                    multiSelectFilterChange
                                "
                                @hideFilterPanel="closeFilterPanel"
                                :isFilterPanelOpen="showFilterPanel"
                                searchInputPlaceHolder="Search universes..."
                                @showFilterPanel="openFilterPannel"
                                :selectCheckboxId="checkboxFilterId"
                                :showSearchInput="true"
                                @clearInputFilter="clearTextFilter"
                                @clearAllFilters="clearAllFilters"
                                searchInputLable="Universe Name"
                            ></filter-panel>
                        </transition-group>
                    </div>
                </div>
            </mds-row>
        </mds-layout-grid>
        <notification-component
            v-if="showNotification"
            :notificationMessage="notificationMessage"
            :notificationType="notificationType"
            :keyType="notificationKey"
            :dismissDelay="5000"
            @close="showNotification = false"
        ></notification-component>
        <!-- Share option Popover -->
        <popover-component
            v-if="toggleSharePopover"
            :popOverSelection="popOverSelection"
            width="200px"
            position="bottom-center"
            triggerBy="ShareButtonId"
            v-model="toggleSharePopover"
            @clickOnShareData="clickOnShareData"
            @clickOnPublishData="clickOnPublishData"
            @close="toggleSharePopover = false"
        ></popover-component>
        <!-- Duplicate Popup -->
        <mds-dialog
            v-model="toggleDuplicate"
            action-required
            title="Duplicate universe?"
        >
            <template v-slot:mds-dialog-supplemental-content>
                <mds-form>
                    <mds-fieldset>
                        <mds-input
                            label="Universe Name"
                            maxlength="250"
                            v-model.trim="universenameduplicate"
                        ></mds-input>
                    </mds-fieldset>
                </mds-form>
            </template>
            <template v-slot:mds-dialog-actions-right>
                <mds-button-container right-aligned>
                    <mds-button
                        type="button"
                        variation="secondary"
                        @click="toggleDuplicate = !toggleDuplicate"
                        v-on:click="duplicateUniverseCancel()"
                    >
                        Cancel
                    </mds-button>
                    <mds-button
                        type="button"
                        variation="primary"
                        @click="toggleDuplicate = !toggleDuplicate"
                        :disabled="!duplicateUniverseButtonDisabled"
                        v-on:click="duplicateUniverseSave()"
                    >
                        Save
                    </mds-button>
                </mds-button-container>
            </template>
        </mds-dialog>
        <!-- Delete Popup -->
        <mds-dialog
            v-model="toggleDelete"
            action-required
            title="Delete this universe?"
        >
            This action cannot be undone, and the universe will be deleted for
            all users with access.
            <template v-slot:mds-dialog-supplemental-content>
                <mds-form>
                    <mds-fieldset>
                        <mds-checkbox
                            value="deleteUniverseChecked"
                            @change="deleteUniverseChecked($event)"
                            >Yes, delete this universe.</mds-checkbox
                        >
                    </mds-fieldset>
                </mds-form>
            </template>
            <template v-slot:mds-dialog-actions-right>
                <mds-button-container right-aligned>
                    <mds-button
                        type="button"
                        variation="secondary"
                        @click="toggleDelete = !toggleDelete"
                        v-on:click="deleteUniverseNo()"
                    >
                        Cancel
                    </mds-button>
                    <mds-button
                        type="button"
                        style="background-color: #ff0000; color: white"
                        @click="toggleDelete = !toggleDelete"
                        :disabled="deleteUniverseButtonDisable"
                        v-on:click="deleteUniverseYes()"
                    >
                        Delete universe
                    </mds-button>
                </mds-button-container>
            </template>
        </mds-dialog>
        <!-- for publish Popup -->
        <dialog-popup
            :toggleDialog="toggleDialog"
            :title="dialogTitle"
            :sub-title="subTitle"
            :selectedAction="selectedAction"
            @cancel="publishNo"
            @emitPublishPopup="publishYes"
        >
        </dialog-popup>
        <!-- Share Popup -->
        <modal-component
            width="600px"
            title="Share Universe"
            :toggleModalComponent="toggleModalComponent"
            :getselectionOfUser="getselectionOfUser"
            :select-multi-row-data="selectMultiRowData"
            :getShareDataSetData="getShareUniverseData"
            @saveModalComponent="saveModalComponent"
            @addSharedUser="AddSharedUser"
            @selectedEntityDeleteButton="selectedEntityDeleteButton"
            @close="closeSharePopUp"
        />
    </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow } from "@mds/layout-grid";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import { mapActions, mapGetters, mapState } from "vuex";
import {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTr,
    MdsTbody,
    MdsTd,
} from "@mds/data-table-4.0.2";
import FilterPanel from "../common_components/FilterPanel.vue";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import MdsPagination from "@mds/pagination";
import HeaderButtonPanel from "../common_components/HeaderButtonPanel.vue";
import MdsEmptyState from "@mds/empty-state";
import MdsDialog from "@mds/dialog";
import MdsForm from "@mds/form";
import MdsInput from "@mds/input";
import MdsCheckbox from "@mds/checkbox";
import MdsFieldset from "@mds/fieldset";
import {
    deleteUniverseData,
    duplicateUniverseData,
    publishUniverse,
    shareUniverse,
    // shareUniverseInformation,
} from "../../services/reporting_service.js";
import DialogPopup from "../common_components/DialogPopup";
import ModalComponent from "../ui_component/ModalComponent.vue";
import { dateFormatterMixin } from "../../mixins/date.js";
import PopoverComponent from "../ui_component/popoverComponent.vue";
import get from "lodash/get";
import { REPORTING_ACTIONS } from "@/store/modules/reporting/constants";

export default {
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsTable,
        MdsThead,
        MdsTh,
        MdsTr,
        MdsTbody,
        MdsTd,
        MdsButton,
        MdsButtonContainer,
        MdsPagination,
        FilterPanel,
        NotificationComponent,
        LoaderComponent,
        HeaderButtonPanel,
        MdsEmptyState,
        MdsDialog,
        MdsForm,
        MdsInput,
        MdsCheckbox,
        MdsFieldset,
        DialogPopup,
        ModalComponent,
        PopoverComponent,
    },
    mixins: [dateFormatterMixin],

    data() {
        return {
            uniTypeFilter: "",
            uniOwnerFilter: "",
            uniSharedPublishFilter: "",
            shareUniverseTableHover: true,
            searchTextInputField: "",
            currentActive: 1,
            checkboxFilterId: [],
            showFilterPanel: false,
            isFilterPanelOpen: true,
            showEnableDisable: true,
            checkBoxFilterKey: 0,
            toggleID: 0,
            universenameduplicate: "",
            toggleSharePopover: false,
            toggleShare: false,
            toggleDuplicate: false,
            toggleDelete: false,
            togglePublish: false,
            showNotification: false,
            notificationMessage: "",
            notificationType: "",
            showLoader: true,
            selectMultiRowData: [],
            selected: new Set(),
            itemTotalCount: new Set(),
            clearCheckboxFilter: false,
            editActionButton: false,
            shareActionButton: false,
            deleteActionButton: false,
            deletePublishActionButton: true,
            viewActionButton: false,
            duplicateActionButton: false,
            createUniverseTitle: "Create Universe",
            getData: [],
            page: 1,
            pageSize: 10,
            firstItem: "",
            lastItem: "",
            totalItems: 0,
            sortValue: false,
            paginationComponentKey: 0,
            selectedCheckboxFilter: new Set(),
            checkboxFilterType: [],
            checkboxFilterOwner: [],
            checkboxFilterSharedPublish: [],
            searchFilterText: "",
            rulesTableKey: 0,
            /**sorting */
            sortType: false,
            sortOrder: -1,
            sortColIndex: 4,
            orderByColumn: "lastupdated",
            deleteUniverseButtonDisable: true,
            universeObjectData: {},

            counterDetails: {
                displayCounterDetails: true,
                count: 0,
                countText: "Selected",
                showVerticalLine: true,
            },

            buttonPanelDetails: [
                {
                    id: "AddButtonId",
                    buttonName: "Create Universe",
                    iconName: "plus",
                    iconColor: "defaultColor",
                    isDisabled: false,
                    showVerticalLine: false,
                    isText: false,
                    permission: "universes-create"
                },
                {
                    id: "DuplicateButtonId",
                    buttonName: "Duplicate",
                    iconName: "open-new",
                    iconColor: "defaultColor",
                    isDisabled: true,
                    showVerticalLine: false,
                    isText: false,
                    permission: "universes-duplicate"
                },
                {
                    id: "ShareButtonId",
                    buttonName: "Share",
                    iconName: "share",
                    isDisabled: true,
                    iconColor: "defaultColor",
                    showVerticalLine: false,
                    isText: false,
                    permission: "universes-share"
                },
                {
                    id: "DeleteButtonId",
                    buttonName: "Delete",
                    iconName: "trash",
                    iconColor: "defaultColor",
                    isDisabled: true,
                    showVerticalLine: false,
                    isText: false,
                    permission: "universes-delete"
                },
            ],

            universesHeader: [
                {
                    fieldName: "listdescription",
                    text: "Name",
                    width: "180px",
                    sortable: true,
                },
                {
                    fieldName: "type",
                    text: "Type",
                    width: "100px",
                    sortable: true,
                },
                {
                    fieldName: "createdby",
                    text: "Owner",
                    width: "180px",
                    sortable: true,
                },
                {
                    fieldName: "sharedpublishstatus",
                    text: "Shared/Public",
                    width: "100px",
                    sortable: true,
                },
                {
                    fieldName: "lastupdated",
                    text: "Last Updated",
                    width: "150px",
                    sorted: -1,
                    sortable: true,
                },
                {
                    fieldName: "updatedby",
                    text: "Updated By",
                    width: "180px",
                    sortable: true,
                },
                {
                    fieldName: "actions",
                    text: "Actions",
                    width: "120px",
                    align: "right",
                },
            ],

            universesRowData: [],

            filterObject: [
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
                {
                    filterHeader: "",
                    selectionType: "",
                    filterList: [],
                },
            ],
            toggleDialog: false,
            subTitle: "",
            dialogTitle: "",
            selectedAction: "",
            toggleModalComponent: false,
            getselectionOfUser: [],
            getShareUniverseData: [],
            addPermission: [],
            removePermission: [],
            togglerowSharePopover: false,
            popOverSelection: "sharePopover",
            checkFilterOpen: false
        };
    },

    props: {
        menuwidth: {
            type: String,
        },
    },

    watch: {
        clearCheckboxFilter() {
            this.itemTotalCount.clear();
            this.counterDetails.count = 0;
            this.clearCheckboxFilter = false;
        },
        getOwnersUniverse(){
            this.getUniversesOwnerFilter([])
        }
    },

    async mounted() {
        this.showLoader = true;
        await this.fetchOwnerUniverse();
        var obj = {
            page: this.page,
            pageSize: this.pageSize,
            searchColumn: "",
            searchType: "",
            searchOwner: "",
            searchStatus: "",
            orderBy: this.orderByColumn,
            sortType: this.sortType,
        };
        await this.fetchUniversesList(obj);
        this.getUniverseData();
        await this.cachedDispatch(REPORTING_ACTIONS.FETCH_USER_LIST);
        this.getAllDataSetUserLists();
        this.showLoader = false;
    },

    computed: {
        ...mapGetters(["cachedDispatch"]),
        ...mapGetters("reporting", [
            "getUniverses",
            "getOwnersUniverse",
            "getShareUniverseInformation",
        ]),
         ...mapState({
            getAllUserList(state) {
                return get(
                    state,
                    "reportingModule.userList.userList",
                    []
                );
            },
        }),

        universeLength() {
            if (this.universesRowData !== undefined) {
                if (this.universesRowData.length > 0) {
                    return this.universesRowData.length;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        },

        selectionStatus() {
            const filtered = this.universesRowData.filter(
                (row) => row.checked === true
            );

            return {
                isIndeterminate:
                    filtered.length > 0 &&
                    filtered.length < this.universesRowData.length,
                isAllSelected:
                    filtered.length === this.universesRowData.length &&
                    filtered.length != 0 &&
                    this.universesRowData != 0,
            };
        },

        duplicateUniverseButtonDisabled() {
            return this.universenameduplicate;
        },

        cssVariables() {
            return {
                "--menuWidth": this.menuwidth,
            };
        },

        topDuplicateIcon() {
            return this.buttonPanelDetails.filter(
                (x) => x.id === "DuplicateButtonId"
            );
        },
        topShareIcon() {
            return this.buttonPanelDetails.filter(
                (x) => x.id === "ShareButtonId"
            );
        },
        topDeleteIcon() {
            return this.buttonPanelDetails.filter(
                (x) => x.id === "DeleteButtonId"
            );
        },
        permissions () {
            return this.$store.state.auth.permissions;
        },
    },

    methods: {
        ...mapActions("reporting", [
            "fetchUniversesList",
            "fetchOwnerUniverse",
            "fetchSharedUniverseInformation",
        ]),

        triggerByPopover(param) {
            return param.toString();
        },

        universeListCallFun() {},

        getUniverseData() {
            let response = this.getUniverses;
            this.universesRowData = response.universelist;
            this.totalItems = response.total;
        },

        getAllDataSetUserLists() {
            let response = this.getAllUserList.data;
            for (let i = 0; i < response.length; i++) {
                let data = {};
                data.value = response[i].email;
                data.text = response[i].email;
                this.getselectionOfUser.push(data);
            }
        },

        AddSharedUser({ selectedUser, accessInput }) {
            let response = this.getShareUniverseData.filter(
                (item) => item.name == selectedUser
            );
            if (response.length > 0) {
                response.forEach((item) => {
                    item.access = accessInput;
                });
                let obj = {};
                obj.email = selectedUser;
                if (accessInput == "Edit") {
                    obj.isEdit = true;
                } else if (accessInput != "Edit") {
                    obj.isEdit = false;
                }
                this.addPermission.push(obj);
            } else if (response.length == 0) {
                let data = {};
                data.name = selectedUser;
                data.access = accessInput;
                data.button = "delete";
                this.getShareUniverseData.push(data);
                let obj = {};
                obj.email = selectedUser;
                if (accessInput == "Edit") {
                    obj.isEdit = true;
                } else if (accessInput != "Edit") {
                    obj.isEdit = false;
                }
                this.addPermission.push(obj);
            }
        },

        closeSharePopUp() {
            this.toggleSharePopover = false;
            this.toggleModalComponent = false;
        },

        selectedEntityDeleteButton(name) {
            let response = this.getShareUniverseData.filter(
                (item) => item.name == name
            );
            this.getShareUniverseData = this.getShareUniverseData.filter(
                (item) => item.name !== name
            );
            this.addPermission = this.addPermission.filter(
                (item) => item.email !== name
            );

            response.forEach((item) => {
                var obj = {};
                obj.email = item.name;
                if (item.access == "Edit") {
                    obj.isEdit = true;
                } else if (item.access == "Read Only") {
                    obj.isEdit = false;
                }
                this.removePermission.push(obj);
            });
        },

        deleteUniverseChecked(e) {
            if (e == true) {
                this.deleteUniverseButtonDisable = false;
            } else if (e == false) {
                this.deleteUniverseButtonDisable = true;
            }
        },

        /**This logic given in MDS documentaion so added in seperate function */
        executeSortingLogicForMdsTable(colIndex) {
            // if your tracked sort order is 0 (none), set it 1 (ascending)
            // if there is already an order being tracked inverse it
            this.sortOrder = this.sortOrder === 0 ? 1 : -this.sortOrder;

            // if the table was already sorted by a different column, then reset that column to 0 (none)
            if (this.sortColIndex !== null && this.sortColIndex !== colIndex) {
                this.$set(this.universesHeader[this.sortColIndex], "sorted", 0);
                this.sortOrder = -1;
            }

            // track the current column as the one being sorted on
            this.sortColIndex = colIndex;
            this.$set(this.universesHeader[colIndex], "sorted", this.sortOrder);
        },

        handleSortEvent(colIndex, colName) {
            this.executeSortingLogicForMdsTable(colIndex);
            this.orderByColumn = colName;
            this.sortOrder == 1
                ? (this.sortType = true)
                : (this.sortType = false);
            this.showLoader = true;
            this.page = 1;
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        getUniversesTypeFilter(selectedFilter) {
            this.filterObject[0].filterHeader = "Type";
            this.filterObject[0].selectionType = "multiple";
            let data = [
                {
                    lable: "Dynamic",
                    id: 1,
                    isChecked: false,
                },
                {
                    lable: "Static",
                    id: 2,
                    isChecked: false,
                },
            ];
            data.map((x) => {
                selectedFilter.forEach((y) => {
                    if (x.lable === y.value.lable) {
                        x.isChecked = true;
                    }
                });
            });
            this.filterObject[0].filterList = data;
        },

        getUniversesOwnerFilter(selectedFilter) {
            let response = this.getOwnersUniverse.data;
            if (response !== undefined) {
                this.filterObject[1].filterHeader = "Owner";
                this.filterObject[1].selectionType = "multiple";
                const data =
                    response &&
                    response.reduce((obj, item, index) => {
                        const id = index;
                        const lable = item.email;
                        obj.push({ id, lable, isChecked: false });
                        return obj;
                    }, []);
                data.map((x) => {
                    selectedFilter.forEach((y) => {
                        if (x.lable === y.value.lable) {
                            x.isChecked = true;
                        }
                    });
                });
                this.filterObject[1].filterList = data;
            }
        },

        getUniversesSharePublishFilter(selectedFilter) {
            this.filterObject[2].filterHeader = "Shared/Public";
            this.filterObject[2].selectionType = "multiple";
            let data = [
                {
                    lable: "Private",
                    id: 1,
                    isChecked: false,
                },
                {
                    lable: "Public",
                    id: 2,
                    isChecked: false,
                },
                {
                    lable: "Shared",
                    id: 3,
                    isChecked: false,
                },
                {
                    lable: "Shared/Public",
                    id: 4,
                    isChecked: false,
                },
            ];
            data.map((x) => {
                selectedFilter.forEach((y) => {
                    if (x.lable === y.value.lable) {
                        x.isChecked = true;
                    }
                });
            });
            this.filterObject[2].filterList = data;
        },

        async commonFunction() {
            this.showLoader = true;
            var obj = {
                page: this.page,
                pageSize: this.pageSize,
                searchColumn: this.searchFilterText,
                searchType: this.uniTypeFilter,
                searchOwner: this.uniOwnerFilter,
                searchStatus: this.uniSharedPublishFilter,
                orderBy: this.orderByColumn,
                sortType: this.sortType,
            };
            await this.fetchUniversesList(obj);
            let response = this.getUniverses;
            this.universesRowData = response.universelist;
            this.totalItems = response.total;
            this.showLoader = false;
        },

        async paginateTable(arg) {
            this.showLoader = true;
            this.page = arg.page;
            this.pageSize = arg.pageSize;
            this.firstItem = arg.firstItem;
            this.lastItem = arg.lastItem;
            var obj = {
                page: this.page,
                pageSize: this.pageSize,
                searchColumn: this.searchFilterText,
                searchType: this.uniTypeFilter,
                searchOwner: this.uniOwnerFilter,
                searchStatus: this.uniSharedPublishFilter,
                orderBy: this.orderByColumn,
                sortType: this.sortType,
            };
            await this.fetchUniversesList(obj);
            let response = this.getUniverses;
            this.universesRowData = response.universelist;
            this.totalItems = response.total;
            this.selectedDataMatched();
            this.showLoader = false;
        },

        selectedDataMatched() {
            if (this.itemTotalCount.size != 0) {
                for (let item of this.itemTotalCount) {
                    for (let i = 0; i < this.universesRowData.length; i++) {
                        if (this.universesRowData[i].listid == item.listid) {
                            this.universesRowData[i] = item;
                        }
                    }
                }
            }
        },

        clearTextFilter() {
            this.clearAllHandleSelectEventCheckox();
            this.showLoader = true;
            this.searchFilterText = "";
            this.page = 1;
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        getDropDownsData() {
            this.vendorsData = this.getVendorList;
        },

        closeFilterPanel() {
            this.checkFilterOpen = false;
            this.showFilterPanel = false;
        },

        openFilterPannel(selectedFilter) {
            this.checkFilterOpen = true;
            this.getUniversesTypeFilter(selectedFilter);
            this.getUniversesOwnerFilter(selectedFilter);
            this.getUniversesSharePublishFilter(selectedFilter);
            this.showFilterPanel = true;
        },

        singalEditActionButton(rowDetails) {
            this.$router.push(`/settings/universes/create?listid=${rowDetails.listid}&type=${rowDetails.type}&name=${rowDetails.listdescription}&ispublic=${rowDetails.ispublic}&iseditable=${rowDetails.iseditable}`);
        },

        singalViewActionButton(rowDetails) {
            this.$router.push(`/settings/universes/view?listid=${rowDetails.listid}&type=${rowDetails.type}&name=${rowDetails.listdescription}&ispublic=${rowDetails.ispublic}&iseditable=${rowDetails.iseditable}`);
        },

        singalDuplicateActionButton(rowDetails) {
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            if (this.selectMultiRowData.length == 0) {
                this.selectMultiRowData.push(rowDetails);
            }
        },

        singalShareActionButton(rowDetails) {
            this.toggleID = rowDetails.listid;
            this.togglerowSharePopover = !this.togglerowSharePopover;
            this.popOverSelection = "sharePopover";
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            if (this.selectMultiRowData.length == 0) {
                this.selectMultiRowData.push(rowDetails);
            }
        },

        async singleDeleteActionButton(rowDetails) {
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            if (this.selectMultiRowData.length == 0) {
                this.selectMultiRowData.push(rowDetails);
            }
            this.deleteUniverseButtonDisable = true;
        },

        deleteUniverseNo() {},

        async deleteUniverseYes() {
            this.showLoader = true;
            let array = [];
            this.selectMultiRowData.forEach((item) => {
                array.push(item.listid);
            });
            var listids = array.join(",");
            let response = await deleteUniverseData(listids);
            await this.fetchOwnerUniverse();
            const object = {
                response,
                ntfnMsg: "The universe has been deleted.",
                ntfnType: "informational",
                ntfnKey: "informational-example",
            };
            this.notificationResponse(object);
        },

        async notificationResponse({
            response,
            ntfnMsg,
            ntfnType,
            ntfnKey,
        }) {
            if (response.data.statuscode == 200) {
                this.notificationMessage = ntfnMsg;
                this.notificationType = ntfnType;
                this.showNotification = true;
                this.notificationKey = ntfnKey;
                this.reset();
                this.page = 1;
                this.commonUniverseFun();
                this.paginationComponentKey += 1;
                this.showLoader = false;
            } else {
                this.showLoader = false;
                this.notificationKey = "error-default";
                this.notificationMessage = response.data.message;
                this.notificationType = "error";
                this.showNotification = true;
            }
        },

        commonUniverseFun() {
            this.showLoader = true;
            this.commonFunction();
            this.selected.clear();
            this.reset();
            this.clearAllHandleSelectEventCheckox();
        },

        onClickPanelButton(event) {
            if (event === "Create Universe") {
                this.addUniverse();
            } else if (event === "Edit") {
                this.editUniverse();
            } else if (event === "Delete") {
                this.deleteUniverse();
            } else if (event === "Share") {
                this.shareUniverses();
            } else if (event === "Duplicate") {
                this.duplicateUniverse();
            }
        },

        addUniverse() {
            this.$router.push({
                name: "Reporting Create Universe",
                params: { createUniverseData: this.createUniverseTitle },
            });
        },

        editUniverse() {
            let obj = {};
            this.selectMultiRowData.forEach((item) => {
                (obj.listid = item.listid),
                    (obj.type = item.type),
                    (obj.listdescription = item.listdescription),
                    (obj.iseditable = item.iseditable);
            });
            this.$router.push({
                name: "Reporting Create Universe",
                params: { editUniverseData: obj },
            });
        },

        deleteUniverse() {
            this.toggleDelete = !this.toggleDelete;
            this.deleteUniverseButtonDisable = true;
        },

        shareUniverses() {
            this.popOverSelection = "sharePopover";
            this.toggleSharePopover = true;
        },

        duplicateUniverse() {
            this.toggleDuplicate = !this.toggleDuplicate;
        },

        async clickOnShareData() {
            this.toggleModalComponent = true;
            this.toggleSharePopover = false;
            this.showLoader = true;
            let listId;
            let array = [];
            this.getShareUniverseData = [];
            if (this.selectMultiRowData.length == 1) {
                this.selectMultiRowData.forEach(async (item) => {
                    listId = item.listid;
                    array = item;
                });
                if (array.isshare == true) {
                    await this.fetchSharedUniverseInformation(listId);
                    let response = this.getShareUniverseInformation.data;
                    if (response.length > 0) {
                        response.forEach((item) => {
                            var obj = {};
                            obj.name = item.email;
                            if (item.iseditallow == true) {
                                obj.access = "Edit";
                            } else if (item.iseditallow == false) {
                                obj.access = "Read Only";
                            }
                            obj.button = "delete";
                            this.getShareUniverseData.push(obj);
                        });
                    }
                }
            }
            this.showLoader = false;
        },

        clickOnPublishData() {
            this.toggleSharePopover = false;
            this.subTitle = `Are you sure you'd like to publish this universe? This will make the universe
       available for all users.`;
            this.dialogTitle = "Publish Universe?";
            this.selectedAction = "Publish";
            this.toggleDialog = true;
        },

        publishNo() {
            this.toggleDialog = false;
            this.toggleSharePopover = false;
        },

        async publishYes() {
            this.toggleDialog = false;
            this.showLoader = true;
            let array = [];
            this.selectMultiRowData.forEach((item) => {
                array.push(item.listid);
            });
            let universeIds = array.join(",");
            let response = await publishUniverse(universeIds);
            const object = {
                response,
                ntfnMsg: "The universe has been published.",
                ntfnType: "success",
                ntfnKey: "success-example",
            };
            this.notificationResponse(object);
        },

        duplicateUniverseCancel() {
            this.universenameduplicate = "";
        },

        async duplicateUniverseSave() {
            let obj = {};
            this.selectMultiRowData.forEach((item) => {
                (obj.listid = item.listid),
                    (obj.duplicateUniverseName = this.universenameduplicate);
            });
            let response = await duplicateUniverseData(
                obj.listid,
                obj.duplicateUniverseName
            );
            const object = {
                response,
                ntfnMsg: "The universe has been duplicated.",
                ntfnType: "success",
                ntfnKey: "success-example",
            };
            this.notificationResponse(object);
            this.universenameduplicate = "";
        },

        onCancelShareUniverseClick() {
            this.toggleShare = false;
        },

        async saveModalComponent() {
            this.showLoader = true;
            let array = [];
            this.selectMultiRowData.forEach((item) => {
                array.push(item.listid);
            });
            let id = array;
            this.addPermission.forEach((result) => {
                this.removePermission.forEach((Element, index) => {
                    if (result.email == Element.email) {
                        this.removePermission.splice(index, 1);
                    }
                });
            });
            let data = {
                ids: id,
                addPermission: this.addPermission,
                removePermission: this.removePermission,
            };
            let response = await shareUniverse(data);
            const obj = {
                response,
                ntfnMsg: "The universe has been shared.",
                ntfnType: "success",
                ntfnKey: "success-default",
            };
            this.notificationResponse(obj);
            this.addPermission = [];
            this.removePermission = [];
            this.showLoader = false;
            this.toggleModalComponent = false;
            this.toggleSharePopover = false;
            this.selectUser = [];
            this.accessInput = "";
        },

        reset() {
            let items = document.querySelectorAll(
                "input[type=checkbox]:checked"
            );
            for (var i = 0; i < items.length; i++) {
                if (items[i].type == 'checkbox') {
                    items[i].checked = false;
                }
            }
            this.$refs.fiterpanel.resetSelectedValue();
            this.searchFilterText = "";
            this.checkboxFilterOwner = [],
            this.checkboxFilterType = [],
            this.checkboxFilterSharedPublish = [],
            this.uniTypeFilter = "";
            this.uniOwnerFilter = "";
            this.uniSharedPublishFilter= "",
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
        },

        getUniverseObject() {
            this.universeObjectData = {
                page: this.page,
                pageSize: this.pageSize,
                searchColumn: this.searchFilterText,
                searchType: this.uniTypeFilter,
                searchOwner: this.uniOwnerFilter,
                searchStatus: this.uniSharedPublishFilter,
                orderBy: this.orderByColumn,
                sortType: this.sortType,
            };
        },

        disableRowIcons(params) {
            this.editActionButton = params === "singleSelect" ? true : false;
            this.shareActionButton = params === "singleSelect" ? true : false;
            this.deleteActionButton = params === "singleSelect" ? true : false;
            this.viewActionButton = params === "singleSelect" ? true : false;
            this.duplicateActionButton =
                params === "singleSelect" ? true : false;
        },

        disableTopIcons(params) {
            this.topDuplicateIcon[0].isDisabled =
                params === "singleSelect" ? false : true;
            this.topShareIcon[0].isDisabled =
                params === "singleSelect" ? false : true;
            this.topDeleteIcon[0].isDisabled =
                params === "singleSelect" ? false : true;
        },

        handleRowSelectEvent(index, event, row) {
            this.selectMultiRowData = this.universesRowData.filter(
                (el) => el.checked
            );
            this.$set(this.universesRowData[index], "checked", event);
            if (event) {
                this.itemTotalCount.add(row);
                this.selectMultiRowData = Array.from(this.itemTotalCount);
            } else {
                this.itemTotalCount.delete(row);
                this.selectMultiRowData = Array.from(this.itemTotalCount);
            }
            if (this.selectMultiRowData.length === 1) {
                this.disableTopIcons("singleSelect");
                this.disableRowIcons("singleSelect");
                this.selectMultiRowData.forEach(item =>{
                    if (item.isduplicate === false){
                        this.buttonPanelDetails[1].isDisabled = true;
                    }
                })
            }
            if (this.selectMultiRowData.length > 1) {
                this.disableTopIcons("singleSelect");
                this.disableRowIcons("singleSelect");
                this.topDuplicateIcon[0].isDisabled = true;
            }
            if (!this.selectMultiRowData.length) {
                this.disableRowIcons("noSelect");
                this.disableTopIcons("noSelect");
            }
            if (row.ispublic === true) {
                this.buttonPanelDetails[3].isDisabled = true;
            }
            // Delete
            const disableDelete =
                this.selectMultiRowData.length &&
                this.selectMultiRowData.some((item) => item.isdelete === false);
            if (!disableDelete && disableDelete !== 0) {
                this.buttonPanelDetails[3].isDisabled = false;
            } else {
                this.buttonPanelDetails[3].isDisabled = true;
            }
            // Share
            const disableShare =
                this.selectMultiRowData.length &&
                this.selectMultiRowData.some((item) => item.isshare === false);
            if (!disableShare && disableShare !== 0) {
                this.buttonPanelDetails[2].isDisabled = false;
            } else {
                this.buttonPanelDetails[2].isDisabled = true;
            }

            this.counterDetails.count = this.itemTotalCount.size;
        },

        handleMultiSelection(event) {
            for (let i = 0; i < this.universesRowData.length; i++) {
                this.$set(
                    this.universesRowData[i],
                    "checked",
                    event.target.checked
                );
            }
        },

        clearAllHandleSelectEventCheckox() {
            this.selectMultiRowData.length = 0;
            this.itemTotalCount.clear();
            this.counterDetails.count = 0;
            this.disableRowIcons("noSelect");
            this.disableTopIcons("noSelect");
        },

        multiSelectFilterChange(event, value, headerTypeDetails) {
            this.page = 1;
            for (let i = 0; i < this.filterObject.length; i++) {
                if (this.filterObject[i].filterHeader == headerTypeDetails) {
                    for (let j = 0; j < this.filterObject[i].filterList.length; j++) {
                        if (this.filterObject[i].filterList[j].lable == value.lable) {
                            this.filterObject[i].filterList[j].isChecked = event;
                        }
                    }
                }
            }
            this.clearAllHandleSelectEventCheckox();
            if (event == true) {
                this.showLoader = true;
                if (headerTypeDetails == "Type") {
                    this.checkboxFilterType.push(value.lable);
                } else if (headerTypeDetails == "Owner") {
                    this.checkboxFilterOwner.push(value.lable);
                } else if (headerTypeDetails == "Shared/Public") {
                    this.checkboxFilterSharedPublish.push(value.lable);
                }
                this.uniTypeFilter = this.checkboxFilterType.join(",");
                this.uniOwnerFilter = this.checkboxFilterOwner.join(",");
                this.uniSharedPublishFilter = this.checkboxFilterSharedPublish.join(",");
                this.commonFunction();
                this.paginationComponentKey += 1;
            } else if (event == false) {
                this.showLoader = true;
                if (headerTypeDetails == "Type") {
                    for (let i = 0; i < this.checkboxFilterType.length; i++) {
                        if (this.checkboxFilterType[i] === value.lable) {
                            this.checkboxFilterType.splice(i, 1);
                        }
                    }
                } else if (headerTypeDetails == "Owner") {
                    for (let i = 0; i < this.checkboxFilterOwner.length; i++) {
                        if (this.checkboxFilterOwner[i] == value.lable) {
                            this.checkboxFilterOwner.splice(i, 1);
                        }
                    }
                } else if (headerTypeDetails == "Shared/Public") {
                    for (let i = 0; i < this.checkboxFilterSharedPublish.length; i++) {
                        if (this.checkboxFilterSharedPublish[i] == value.lable) {
                            this.checkboxFilterSharedPublish.splice(i, 1);
                        }
                    }
                }

                this.uniTypeFilter = this.checkboxFilterType.join(",");
                this.uniOwnerFilter = this.checkboxFilterOwner.join(",");
                this.uniSharedPublishFilter = this.checkboxFilterSharedPublish.join(",");
                this.commonFunction();
                this.paginationComponentKey += 1;
            }
        },

        searchFilter(searchText) {
            this.clearAllHandleSelectEventCheckox();
            this.searchFilterText = searchText;
            this.showLoader = true;
            this.page = 1;
            this.commonFunction();
            this.paginationComponentKey += 1;
        },

        clearAllFilters() {
            this.clearCheckboxFilter = true;
            this.checkboxFilterOwner.length = 0;
            this.reset();
            this.clearTextFilter();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/styles.scss";

.isFilterOpen {width: 82%}

.searchInputLabel {
    font-family: Univers, HelveticaNeue, Helvetica Neue, Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
    font-weight: 600;
    color: #1e1e1e;
    margin-top: 0;
    margin-bottom: 8px;
}

.universes-header {
    margin-top: 16px;
    @include mds-level-2-heading($bold: false);
}

.universesTable {
    margin-top: 13px;
}

.tableDataRow {
    padding: 0;
}

.leftBlock {
    width: 94%;
    position: relative;
    overflow: hidden;
    margin-left: 16px;
    margin-right: 71px;
}

.rightBlock {
    width: 55px;
    padding: 0;
    margin-left: calc(100vw - 55px - var(--menuWidth));
    min-height: 100vh;
    border: 1px solid #979797;
    border-top: 1px solid #cccccc;
    position: fixed;
    z-index: 1;
    background-color: white;
}

.showFilterRightBlock {
    width: 292px;
    margin-left: calc(100vw - 292px - var(--menuWidth));
    position: fixed;
    z-index: 1;
}

.showFilterLeftBlock {
    max-width: 96%;
    margin-left: 16px;
    margin-right: 71px;
}

.mds-data-table__header-selection___VueMDS3Demo {
    left: -1px;
    position: relative;
    vertical-align: bottom;
    width: 15px;
}

::v-deep .mds-data-table__header-selection___VueMDS3Demo {
    left: -1px;
    position: relative;
    text-align: left;
    vertical-align: bottom;
    width: 2%;
}

::v-deep .mds-data-table__table___VueMDS3Demo {
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    color: #1e1e1e;
    display: table;
    max-width: 100%;
    table-layout: fixed;
    width: 100%;
    margin-top: 16px;
}

.actionButton {
    margin-left: 5px;
    margin-right: 5px;
}

.no-results-message {
    margin: auto;
    min-width: 50%;
    margin-top: 16px;
}

::v-deep
    .mds-data-table__cell--right___VueMDS3Demo
    .mds-data-table__header-cell-text___VueMDS3Demo,
.mds-data-table__cell--right___VueMDS3Demo
    .mds-data-table__header-cell-button___VueMDS3Demo {
    text-align: right;
    margin-right: 7px;
}

.move-cancel-save-button-container {
    margin-right: calc(292px - 55px);
}

.move-pagination-show-info::v-deep .mds-pagination__show-items___VueMDS3Demo {
    margin-right: calc(292px - 55px);
}

.pagination-component {
    position: fixed;
    width: calc(100vw - 88px - var(--menuWidth));
    bottom: 0;
}

.universe_middleSection{  
    margin: 0px;
    height: auto;
    max-height: 70vh;
    overflow: auto;
    margin-top: 13px;
}

.universe_table_section{
    min-height: 64vh;
}
</style>
